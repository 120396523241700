import type { TimeInterval } from '@dextools/utils';
import type { Chain } from './chain.model';
import type { BasePairsSearchResultItem } from './pairs-search.model';
import type { PairListAuditsData } from './audit.model';
import type { PairIdentity } from './pair-identity.model';
import type { ContractIdentity } from './contract-identity.model';
import type { Favorite } from './favorites.model';
import type { ApiCategory } from './shared-api/base-shared-api.model';

export type Interval = '5m' | '1h' | '6h' | '24h';

export type TimeIntervalDirection = 'lessThan' | 'moreThan';

export type Order = 'asc' | 'desc';

export enum TypeList {
  hotPairs = 'hot-pairs',
  pairList = 'pairs',
  gainers = 'gainers',
  losers = 'losers',
  socials = 'socials',
  exchanges = 'exchanges',
  liquidityUnlocksUpcoming = 'liquidity-unlocks-upcoming',
  liquidityUnlocksRecent = 'liquidity-unlocks-recent',
  tokenUnlocksUpcoming = 'token-unlocks-upcoming',
  tokenUnlocksRecent = 'token-unlocks-recent',
  createdTokens = 'createdTokens',
  upcomingOpening = 'upcoming-pools-opening',
  favorites = 'favorites',
  category = 'category',
}

export enum TypeListSEO {
  hotPairs = 'hot-pairs',
  pairList = 'pairs',
  gainers = 'gainers',
  losers = 'losers',
  socials = 'new-socials',
  exchanges = 'exchanges',
  liquidityUnlocksUpcoming = 'upcoming-liquidity-unlocks',
  liquidityUnlocksRecent = 'recent-liquidity-unlocks',
  tokenUnlocksUpcoming = 'upcoming-token-unlocks',
  tokenUnlocksRecent = 'recent-token-unlocks',
  createdTokens = 'new-tokens',
  upcomingOpening = 'upcoming-pools-opening',
  favorites = 'favorites',
  category = 'category',
}

export interface PairListParams extends AdvancedPairFilters {
  interval?: Interval;
  limit: number;
  exchange?: string;
  page: number;
}

export interface AdvancedPairFilters {
  exchange?: string;
  minLiquidity?: string;
  maxLiquidity?: string;
  minVolume?: string;
  maxVolume?: string;
  minSwaps?: string;
  maxSwaps?: string;
  minFdv?: string;
  maxFdv?: string;
  excludeNative?: boolean;
  chain?: Chain;
  dextScore?: number;
  audit?: 'verified' | 'clean' | 'blue' | 'green' | 'orange';
  creationTimeSince?: number;
  creationGreaterTimeRange?: number;
  creationLowerTimeRange?: number;
  default?: boolean;
  socialInfo?: boolean;
  limit?: number;
  interval?: Interval;
  sort?: string;
  order?: Order;
  category?: string;
}

export interface TimeFilter {
  number: number;
  timeInterval: TimeInterval;
}

export interface PairData extends Partial<BasePairsSearchResultItem> {
  identity: PairIdentity;
  contract: ContractIdentity;
  favorite: Favorite;
  liquidity?: number;
  price?: number;
  price24h?: number;
  price6h: number;
  price1h: number;
  price5m: number;
  priceVariation: number;
  priceVariationFormatted: string;
  chainLogo: string;
  swaps24h?: number;
  swaps6h: number;
  swaps1h: number;
  swaps5m: number;
  totalMarketCap?: number;
  volume24h?: number;
  volume6h?: number;
  volume1h?: number;
  volume5m?: number;
  swaps?: number;
  volume?: number;
  logo?: string;
  altLogo?: string;
  audit?: PairListAuditsData;
  dextScore?: number;
  isStableNativePair?: boolean;
  creationTime?: number;
  volatility: number;
  isFromTokenCreator: boolean;
  categories?: ApiCategory[];
}

export interface CustomAdvancedFilters {
  _id?: string;
  name: string;
  chain: string;
  default: boolean;
  filter: AdvancedPairFilters;
}

export interface CustomAdvancedFiltersResponse {
  _id?: string;
  name: string;
  chain: string;
  default: boolean;
  filter: string;
}
